<template>
  <div class="admin-container">
    <template>
      <v-row>
        <v-col cols="12" md="12">
          <v-btn
            tile
            small
            @click="
              $router.push({
                name: 'ProjectingSubdirectory-detail',
                params: {
                  id: $route.params.id,
                  subId: $route.params.subId,
                },
              })
            "
            >Zpět</v-btn
          >
        </v-col>
      </v-row>
      <h3 class="mt-5 mb-8 col-secodary-2">Nový dokument</h3>
      <template v-if="!isError">
        <v-row>
          <v-col cols="12" md="3"
            ><v-text-field
              v-model="data.name"
              label="Název dokumentu *"
            ></v-text-field
          ></v-col>
        </v-row>
        <template v-if="$route.query.documentType == 'PHOTO'">
          <input
            style="display: none"
            ref="photoFile"
            type="file"
            accept="image/*"
            class="input--file"
            @input="handleInputFile($event.target.files[0])"
          />
          <div class="d-flex">
            <div v-if="document" class="document-added mr-6">
              <div
                class="document-document-mini"
                :style="correctIcon(document)"
              ></div>
              <div class="document-extension">
                {{ document.extension ? document.extension.toUpperCase() : '' }}
              </div>
              <div
                class="document-text document-text--overflow document-text--name mt-2"
              >
                {{ document.client_name }}
              </div>
              <div class="document-close">
                <button @click="handleDeleteFile">
                  <img :src="require('@/assets/zavrit.svg')" alt="" />
                </button>
              </div>
            </div>
            <button
              class="document-input-add"
              @click="handleClick('photoFile')"
            >
              <div class="document-input-add-plus">+</div>
              <div class="document-input-add-text">Vložit fotografii</div>
            </button>
          </div>
        </template>
        <template v-if="$route.query.documentType == 'DOCUMENT'">
          <input
            style="display: none"
            ref="documentFile"
            type="file"
            class="input--file"
            @input="handleInputFile($event.target.files[0])"
          />
          <div class="d-flex">
            <div v-if="document" class="document-added mr-6">
              <div
                class="document-document-mini"
                :style="correctIcon(document)"
              ></div>
              <div class="document-extension">
                {{ document.extension ? document.extension.toUpperCase() : '' }}
              </div>
              <div
                class="document-text document-text--overflow document-text--name mt-2"
              >
                {{ document.client_name }}
              </div>
              <div class="document-close">
                <button @click="handleDeleteFile">
                  <img :src="require('@/assets/zavrit.svg')" alt="" />
                </button>
              </div>
            </div>
            <button
              class="document-input-add"
              @click="handleClick('documentFile')"
            >
              <div class="document-input-add-plus">+</div>
              <div class="document-input-add-text">Vložit dokument</div>
            </button>
          </div>
        </template>
        <template v-if="$route.query.documentType == 'TAKE_PHOTO'">
          <input
            style="display: none"
            ref="cameraFile"
            type="file"
            class="input--file"
            accept="image/*;"
            capture="camera"
            @input="handleInputFile($event.target.files[0])"
          />
          <div class="d-flex">
            <div v-if="document" class="document-added mr-6">
              <div
                class="document-document-mini"
                :style="correctIcon(document)"
              ></div>
              <div class="document-extension">
                {{ document.extension ? document.extension.toUpperCase() : '' }}
              </div>
              <div
                class="document-text document-text--overflow document-text--name mt-2"
              >
                {{ document.client_name }}
              </div>
              <div class="document-close">
                <button @click="handleDeleteFile">
                  <img :src="require('@/assets/zavrit.svg')" alt="" />
                </button>
              </div>
            </div>

            <button
              class="document-input-add"
              @click="handleClick('cameraFile')"
            >
              <div class="document-input-add-plus">+</div>
              <div class="document-input-add-text">Pořídit fotografii</div>
            </button>
          </div>
        </template>
        <v-row>
          <v-col cols="12" md="3"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3"></v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col class="text-right">
            <v-btn
              small
              tile
              color="secondary"
              class="mr-4"
              @click="
                $router.push({
                  name: 'ProjectingSubdirectory-detail',
                  params: {
                    id: $route.params.id,
                    subId: $route.params.subId,
                  },
                })
              "
              >Zrušit</v-btn
            >
            <v-btn small tile color="primary" @click="store">Uložit</v-btn>
          </v-col>
        </v-row>
      </template>
    </template>
  </div>
</template>

<script>
import bus from '../plugins/bus';
import authHandler from '../api/authHandler';
import permissionDenied from '../api/permissionDenied';
import projectingSubdirectoryService from '../api/projectingSubdirectoryService';
import errorHandler from '../api/errorHandler';
import documentService from '../api/documentService';
import projectingDocumentService from '../api/projectingDocumentService';
const IMAGES_EXT = ['jpg', 'jpeg', 'svg', 'png'];
const PDF = 'pdf';
const ZIP = 'zip';
const PPT = 'ppt';
const XLS = 'xls';

export default {
  data() {
    return {
      data: {
        name: null,
        type: 'CHILD',
        document_id: null,
        target_id: this.$route.params.subId,
      },
      document: null,
      isError: false,
      types: [
        {
          section: 'CURRENT_PROJECT_VERSION',
          name: 'Aktuální verze projektu',
        },
      ],
    };
  },
  async created() {
    permissionDenied(
      this.user,
      this.$router,
      'SUPERUSER',
      'PROJECT_MANAGER',
      'MAIN_SUPERUSER',
      'MAIN_PROJECT_MANAGER'
    );
    if (!this.$route.query.documentType) {
      this.isError = true;
      bus.$emit('snackbar', {
        text: 'Není vybraný typ dokumentu.',
        color: 'error',
      });
    }
  },
  methods: {
    correctIcon(doc) {
      let icon = 'document.svg';
      if (IMAGES_EXT.includes(doc.extension)) icon = 'jpg.svg';
      if (PDF == doc.extension) icon = 'pdf.svg';
      if (doc.extension.includes(ZIP)) icon = 'zip.svg';
      if (doc.extension.includes(PPT)) icon = 'ppt.svg';
      if (doc.extension.includes(XLS)) icon = 'xls.svg';
      return {
        backgroundImage: `url(${require('@/assets/' + icon)})`,
      };
    },
    async handleDeleteFile() {
      try {
        bus.$emit('processing');
        await documentService._delete(this.data.document_id);
        this.data.document_id = null;
        this.document = null;
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    handleClick(ref) {
      this.$refs[ref].click();
    },
    async handleInputFile(f) {
      try {
        console.debug(f);
        bus.$emit('processing');
        const { data } = await documentService.store(f);
        this.data.document_id = data.id;
        this.document = data;
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: `Podařilo se nahrát ${
            this.$route.query.documentType == 'DOCUMENT' ? 'dokument' : 'fotku'
          }`,
          color: 'info',
        });
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    async store() {
      try {
        bus.$emit('processing');
        const response = await projectingDocumentService.store(
          this.data,
          this.$route.params.id,
          this.$route.params.subId
        );
        console.debug('Response', response);
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: 'Uloženo',
          color: 'success',
        });
        this.$router.push({
          name: 'ProjectingSubdirectory-detail',
          params: {
            id: this.$route.params.id,
            subId: this.$route.params.subId,
          },
        });
      } catch (error) {
        console.error(error);
        bus.$emit('processing', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {},
};
</script>

<style></style>
